export const CREATE_SHOP_REQUEST = 'CREATE_SHOP_REQUEST';
export const CREATE_SHOP_SUCCESS = 'CREATE_SHOP_SUCCESS';
export const CREATE_SHOP_FAILURE = 'CREATE_SHOP_FAILURE';
export const EDIT_SHOP_REQUEST = 'EDIT_SHOP_REQUEST';
export const EDIT_SHOP_SUCCESS = 'EDIT_SHOP_SUCCESS';
export const EDIT_SHOP_FAILURE = 'EDIT_SHOP_FAILURE';
export const SET_DELIVERY_AREA = 'SET_DELIVERY_AREA';
export const SET_DELIVERY_DAYS = 'SET_DELIVERY_DAYS';
export const SET_PICKUP_ADDRESS = 'SET_PICKUP_ADDRESS';
export const SET_SHOP = 'SET_SHOP';
export const SET_VALID_SHOP = 'SET_VALID_SHOP';
export const GET_LAT_LNG_REQUEST = 'GET_LAT_LNG_REQUEST';
export const GET_LAT_LNG_SUCCESS = 'GET_LAT_LNG_SUCCESS';
export const GET_LAT_LNG_FAILURE = 'GET_LAT_LNG_FAILURE';
export const GET_SHOP_REQUEST = 'GET_SHOP_REQUEST';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE';
export const GET_SHOP_FAILURE_NOT_FOUND = 'GET_SHOP_FAILURE_NOT_FOUND';
export const SET_PICKUP_SCHEDULE = 'SET_PICKUP_SCHEDULE';
export const SET_CONTACT = 'SET_CONTACT';
export const CREATE_STRIPE_ACCOUNT_REQUEST = 'CREATE_STRIPE_ACCOUNT_REQUEST';
export const CREATE_STRIPE_ACCOUNT_SUCCESS = 'CREATE_STRIPE_ACCOUNT_SUCCESS';
export const CREATE_STRIPE_ACCOUNT_FAILURE = 'CREATE_STRIPE_ACCOUNT_FAILURE';
export const CHECK_STRIPE_DETAILS_SUBMITTED_REQUEST = 'CHECK_STRIPE_DETAILS_SUBMITTED_REQUEST';
export const CHECK_STRIPE_DETAILS_SUBMITTED_SUCCESS = 'CHECK_STRIPE_DETAILS_SUBMITTED_SUCCESS';
export const CHECK_STRIPE_DETAILS_SUBMITTED_FAILURE = 'CHECK_STRIPE_DETAILS_SUBMITTED_FAILURE';
export const GET_FORMATTED_SHOP_ADDRESS_REQUEST = 'GET_FORMATTED_SHOP_ADDRESS_REQUEST';
export const GET_FORMATTED_SHOP_ADDRESS_SUCCESS = 'GET_FORMATTED_SHOP_ADDRESS_SUCCESS';
export const GET_FORMATTED_SHOP_ADDRESS_FAILURE = 'GET_FORMATTED_SHOP_ADDRESS_FAILURE';