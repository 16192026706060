export const SET_PRODUCT_IMAGES_PREVIEW = 'SET_PRODUCT_IMAGES_PREVIEW';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const SET_LISTING_DETAILS = 'SET_LISTING_DETAILS';
export const SET_PRODUCT_WEIGHT = 'SET_PRODUCT_WEIGHT';
export const SET_PRICING_INVENTORY = 'SET_PRICING_INVENTORY';
export const SET_ADDONS = 'SET_ADDONS';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_PERSONALIZATIONS = 'SET_PERSONALIZATIONS';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const SET_PRODUCT_EDIT = 'SET_PRODUCT_EDIT';
export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const SET_CUSTOM_FORM = 'SET_CUSTOM_FORM';
export const GET_PRODUCTS_COUNT_REQUEST = 'GET_PRODUCTS_COUNT_REQUEST';
export const GET_PRODUCTS_COUNT_SUCCESS = 'GET_PRODUCTS_COUNT_SUCCESS';
export const GET_PRODUCTS_COUNT_FAILURE = 'GET_PRODUCTS_COUNT_FAILURE';
export const SET_VALID_PRODUCT = 'SET_VALID_PRODUCT'