export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE';

export const USER_SIGNOUT_REQUEST = 'USER_SIGNOUT_REQUEST';
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS';
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const GET_FORMATTED_ADDRESS_REQUEST = 'GET_FORMATTED_ADDRESS_REQUEST';
export const GET_FORMATTED_ADDRESS_SUCCESS = 'GET_FORMATTED_ADDRESS_SUCCESS';
export const GET_FORMATTED_ADDRESS_FAILURE = 'GET_FORMATTED_ADDRESS_FAILURE';

export const IS_LOGGED_IN_REQUEST = 'IS_LOGGED_IN_REQUEST';
export const IS_LOGGED_IN_SUCCESS = 'IS_LOGGED_IN_SUCCESS';
export const IS_LOGGED_IN_FAILURE = 'IS_LOGGED_IN_FAILURE';

export const RESET_USER = 'RESET_USER';
export const RESET_USER_ERROR = 'RESET_USER_ERROR';

export const SET_ATTEMPTED_ROUTE = 'SET_ATTEMPTED_ROUTE'